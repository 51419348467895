import React from 'react'
import { useState } from "react";
import ErrorMessage from "./ErrorMessage";
import TxList from "./TxList";

const startPayment = async ({ setError, setTxs, ethers, addr }) => {
    try {
        if (!window.ethereum)
            throw new Error("No crypto wallet found. Please install it.");

        await window.ethereum.send("eth_requestAccounts");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        ethers.utils.getAddress(addr);
        const tx = await signer.sendTransaction({
            to: addr,
            value: ethers.utils.parseEther(ethers)
        });
        console.log({ ethers, addr });
        console.log("tx", tx);
        setTxs([tx]);
    } catch (err) {
        setError(err.message);
    }
};

const Binance = () => {


    const [error, setError] = useState();
    const [txs, setTxs] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        setError();
        await startPayment({
            setError,
            setTxs,
            ethers: data.get("ethers"),
            addr: data.get("addr")
        });
    };



    return (
        <div>
            <div>
                <form className="m-4" onSubmit={handleSubmit}>
                    <div className="credit-card w-full lg:w-1/2 sm:w-auto shadow-lg mx-auto rounded-xl bg-white">
                        <main className="mt-4 p-4">
                            <h1 className="text-xl font-semibold text-gray-700 text-center">
                                Send ETH payment
                            </h1>
                            <div className="">
                                <div className="my-3">
                                    <input
                                        type="text"
                                        name="addr"
                                        className="w-full py-2 rounded-lg border-2 border-gray-200 outline-none bg-gray-500 justify-center flex"
                                        placeholder="Recipient Address"
                                    />
                                </div>
                                <div className="my-3">
                                    <input
                                        name="ether"
                                        type="text"
                                        className="w-full py-2 rounded-lg border-2 border-gray-200 outline-none bg-gray-500 justify-center flex"
                                        placeholder="Amount in ETH"
                                    />
                                </div>
                            </div>
                        </main>
                        <footer className="p-4">
                            <button
                                type="submit"
                                className="btn btn-primary submit-button focus:ring focus:outline-none w-full"
                            >
                                Pay now
                            </button>
                            <ErrorMessage message={error} />
                            <TxList txs={txs} />
                        </footer>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default Binance; 