import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { Helmet } from "react-helmet";
import UserContext from './context/UserContext';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserContext>
    <Helmet>
    <meta
      name="keywords"
      content="spices, spice products, cooking ingredients, seasoning, gourmet spices, organic spices, exotic spices, culinary flavors, kitchen essentials, food seasoning, spice blends, spice mixes, cooking spices, herbs, pofffoods, poff foods, pofffoods spices, gourmet food products, culinary herbs, natural spices, premium spices, flavorful seasonings, cooking condiments, authentic spices, high-quality spices, cooking essentials, kitchen spices, exotic flavors, spice collections"
    />
    <meta
      name="description"
      content="Discover premium spice products at PoffFoods - Your ultimate destination for high-quality spices and seasoning blends And elevate your cooking experience."
    />
    <meta property="og:title" content="PoffFoods" />
    <meta property="og:site_name" content="PoffFoods.com" />
    <title>PoffFoods</title>
    </Helmet>
      <Provider store={store}>
        <App />
      </Provider>
    </UserContext>
  </React.StrictMode>
);

reportWebVitals();
