import React from 'react';
import './Error.css';
import '../components/wrapper/style.css';

const Error = () => {
  return (
    <div className="error-container"   >
      <section className="page_404 lg:ml-48 text-center"   >
        <div className="container"   >
          <div className="row"   >  
            <div className="col-sm-12"   >
              <div className="col-sm-10 col-sm-offset-1 text-center"   >
                <div className="four_zero_four_bg" >
                  <h1 className="text-center">404</h1>
                </div>
                <div className="contant_box_404">
                  <h4 className="">Looks like you're lost</h4>
                  <p>The page you are looking for is not available!</p>
                  <a href="/">
                    <button className="poffbtn">
                      Go Back
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Error;
