import React from 'react';
import mess from ".././Images/message.png";
const WhatsAppIcon = () => {
  const buttonStyle = {
    height: "53px",
    width: "53px",
  };

  return (
    <div className="whatsapp-container">
      {/* WhatsApp Button */}
      <a className='whatsapp-link' href="https://wa.link/m9hjb6">
        <img src="https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo-whatsapp-512.png" style={buttonStyle} alt="WhatsApp" />
      </a>
    </div>
  );
};

export default WhatsAppIcon;