import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import "./Perfect.css"
const Perfectmoney = () => {
  const { cartList } = useSelector((state) => state.cart);
  const [couponCode, setCouponCode] = useState(localStorage.getItem("couponCode") || '');
  const [isCouponApplied, setIsCouponApplied] = useState((localStorage.getItem("isCouponApplied") || '0') == '1');
  const [discount, setDiscount] = useState(parseInt(localStorage.getItem("discount") || 0));
  const dispatch = useDispatch();



  const totalPrice = cartList.reduce(
    (price, item) => price + item.qty * item.price,
    0
  );

  useEffect(() => {
    // Use setTimeout to trigger the click event after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      document.getElementById('pay').click();
    }, 10);

    // Cleanup the timeout to avoid any memory leaks
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures that this effect runs only once


  return (
    <Fragment>
  <form className='form' action="https://perfectmoney.com/api/step1.asp" method="POST">
<input type="hidden" name="PAYEE_ACCOUNT" value="U45136923"/>
<input type="hidden" name="PAYEE_NAME" value="MAILSPRISM "/>
<input type="hidden" name="PAYMENT_ID" value="order.1239"/>
<input type="text" name="PAYMENT_AMOUNT" value={(totalPrice - (totalPrice * discount) / 100).toFixed(2)}/><br/>
<input type="hidden" name="PAYMENT_UNITS" value="USD"/>
<input type="hidden" name="STATUS_URL" value="mrohyeah888@gmail.com"/>
<input type="hidden" name="PAYMENT_URL" value="https://mailsprism.com/"/>
<input type="hidden" name="PAYMENT_URL_METHOD" value="LINK"/>
<input type="hidden" name="NOPAYMENT_URL" value="https://mailsprism.com/checkout"/>
<input type="hidden" name="NOPAYMENT_URL_METHOD" value="LINK"/>
<input type="hidden" name="SUGGESTED_MEMO" value=""/>
<input type="hidden" name="BAGGAGE_FIELDS" value=""/>
<input type="submit" name="PAYMENT_METHOD" id='pay' value="Pay Now!"/>
</form>


    </Fragment>
  );
};

export default Perfectmoney;
