// firebase.config.js

import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD_Yelscc0JhVhweByHT_fcY5bzxDc31fg",
  authDomain: "pofffoods.firebaseapp.com",
  projectId: "pofffoods",
  storageBucket: "pofffoods.appspot.com",
  messagingSenderId: "386711289120",
  appId: "1:386711289120:web:9bd490ca7a19f09510eb45",
  measurementId: "G-55Y06W5HR6"
};

const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

export { db, storage, app };