import React, { useContext } from 'react'
import "./Signin.css";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/UserContext';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';



const Signin = () => {
    const { createUser, signInWithGoogle, user, signIn, logOut } = useContext(AuthContext);
    const navigate = useNavigate();


    console.log('context', user);
    const handleSubmit = event =>{
        event.preventDefault();
        const form = event.target;
        const email = form.email.value;
        const password = form.password.value;
        console.log(email, password);

       
        signIn(email, password)
            .then(result => {
                const user = result.user;
                console.log(user);
                form.reset();

                Swal.fire({
                    icon: 'success',
                    title: 'Sign In Successful!',
                    text: `Welcome back, ${user.email}!`,
                });

                
              
            })
            .catch(error => {
                console.error(error);
                // Display error alert if email does not exist
                if (error.code === "auth/invalid-credential") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Email does not exist!',
                    });
                }
            });
    }

    const handleGoogleSignIn = () => {
        signInWithGoogle()
            .then(result => {
                const user = result.user;
                console.log(user);
            })
            .catch(error => console.log(error))
    }

    const handleSignOut = () => {
        logOut()
            .then(() => { })
            .catch(error => console.error(error));
    }


    return (
        <div className='ack'>
            <div class="min-h-screen fiv bg-gray-100 text-gray-900 flex justify-center">
                <div class="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
                    <div class="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
                        <form onSubmit={handleSubmit}>
                            <div class="mt-12 flex flex-col items-center">
                            {user?.email && 
                                  <div className="flex rounded-full p-2 pr-3">
                                  <img className="rounded-full w-10 h-10" src={user.photoURL} alt="" />
                                  <div className="ml-2">
                                    <p className="font-medium">{user.displayName}</p>
                                    <p className="useremail text-xs">{user.email}</p>
                                  </div>
                                </div>
                                  }
                                <h1 class="text-2xl xl:text-3xl font-extrabold">
                                    Sign in
                                </h1>
                                <div class="w-full flex-1 mt-8">
                                    <div class="flex flex-col items-center">
                                        <button
                                            onClick={handleGoogleSignIn}
                                            class="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline">
                                            <div class="bg-white p-2 rounded-full">
                                                <svg class="w-4" viewBox="0 0 533.5 544.3">
                                                    <path
                                                        d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                                                        fill="#4285f4" />
                                                    <path
                                                        d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                                                        fill="#34a853" />
                                                    <path
                                                        d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                                                        fill="#fbbc04" />
                                                    <path
                                                        d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                                                        fill="#ea4335" />
                                                </svg>
                                            </div>
                                            <span class="ml-4">
                                                Sign In with Google
                                            </span>
                                        </button>

                                        <button onClick={handleSignOut} className=" btn-sm underline mt-2">Log Out</button>


                                    </div>

                                    <div class="my-12 border-b text-center">
                                        <div
                                            class="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                                            Or sign up with e-mail
                                        </div>
                                    </div>

                                    <div class="mx-auto max-w-xs">

                                        <input
                                            name="email"
                                            class="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                                            type="email" placeholder="Email" />
                                        <input
                                            name="password"
                                            class="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                                            type="password" placeholder="Password" />

                                        <button
                                            class="mt-5 tracking-wide font-semibold bg-black text-gray-100 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
                                            <svg class="w-6 h-6 -ml-2" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                                                <circle cx="8.5" cy="7" r="4" />
                                                <path d="M20 8v6M23 11h-6" />
                                            </svg>
                                            <span class="ml-3">
                                                Sign in
                                            </span>
                                        </button>
                                        <p class="mt-6 text-xs text-gray-600 text-center">
                                            Create An Account

                                            <Link to="/signup" class=" no-underline border-dotted">
                                                ‎ ‎      Sign-Up
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="flex-1 bg-indigo-100 text-center hidden lg:flex">
                        <div class="back m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signin
