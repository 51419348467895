import { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhatsApp from "./components/Whatsapp";
import Perfectmoney from "./pages/Perfectmoney";
import { Helmet } from "react-helmet";
import Error from "./pages/Error";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import Binance from "./pages/Binance";
import { useEffect } from "react";
import List from "./pages/List";
import Mess from "./components/Facebook";

const Home = lazy(() => import("./pages/Home"));
const Shop = lazy(() => import("./pages/Shop"));
const Cart = lazy(() => import("./pages/Cart"));
const About = lazy(() => import("./pages/About"));
const Testimonials = lazy(() => import("./pages/Testimonials"));
const Product = lazy(() => import("./pages/Product"));
const Checkout = lazy(() => import("./pages/Checkout"));
const Blog = lazy(() => import("./pages/Blog"));
const Contact = lazy(() => import("./pages/Contact"));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <NavBar />
        <Helmet>
          <meta
            name="keywords"
            content="spices, spice products, cooking ingredients, seasoning, gourmet spices, organic spices, exotic spices, culinary flavors, kitchen essentials, food seasoning, spice blends, spice mixes, cooking spices, herbs, pofffoods, poff foods, pofffoods spices, gourmet food products, culinary herbs, natural spices, premium spices, flavorful seasonings, cooking condiments, authentic spices, high-quality spices, cooking essentials, kitchen spices, exotic flavors, spice collections"
          />
          <meta
            name="description"
            content="Discover premium spice products at PoffFoods - Your ultimate destination for high-quality spices and seasoning blends And elevate your cooking experience."
          />
          <meta property="og:title" content="PoffFoods" />
          <meta property="og:site_name" content="PoffFoods.com" />
          <title>PoffFoods</title>
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/Perfectmoney" element={<Perfectmoney />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route exact path="/list" component={<List />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shop/:id" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/binance" element={<Binance />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
      <WhatsApp />
      <Mess />
    </Suspense>
  );
}

export default App;
