"use client";
import React from 'react'
import { FacebookProvider, CustomChat } from 'react-facebook';
function Mess() {
    return (
        <div>
            <FacebookProvider appId="3676684705910275" chatSupport>
                <CustomChat pageId="204911416039858" minimized={true} />
            </FacebookProvider>
        </div>
    )
}

export default Mess;
